<template>
  <div class="SignIn">
    <div class="header">
      <div>您好，请登录</div>
      <img
        src="@/assets/images/erweima.png"
        @click="gootherpage('qrcode')"
        alt=""
      />
    </div>
    <div class="user-box">
      <input
        class="name"
        v-model="form.username"
        placeholder="用户名"
        type="text"
      />
      <input
        class="password"
        v-model="form.password"
        placeholder="密码"
        type="password"
      />
      <div class="Tips">
        <i v-show="errorflag" class="iconfont icon-a-zu869">
          <span>密码输入错误</span></i
        >
      </div>
      <div class="VerificationCode">
        <input placeholder="验证码" type="text" v-model="form.codeValue" />
        <img :src="codeimg" alt="" @click="Codeget" />
      </div>
      <button @click="loginin">登录</button>
      <div class="operation">
        <div @click="gootherpage('forgotpassword')">忘记密码</div>
        <div>|</div>
        <div @click="gootherpage('register')">快速注册</div>
      </div>
      <!-- @click="gootherwx" 老版本直接新窗口打开 -->
      <div class="wximg" @click="gootherpage('qrcode')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-weixin1"></use>
        </svg>
      </div>
    </div>
    <div class="careful">注册登录即代表同意 《服务协议》 和《隐私政策》</div>
  </div>
</template>

<script>
import { getVerificationCode, getloginin, getuserinfo, getwxcode } from "@/api";
export default {
  name: "SignIn",
  data() {
    return {
      errorflag: false,
      codeimg: "",
      form: {
        username: "",
        password: "",
        codeKey: "",
        codeValue: "",
      },
    };
  },
  methods: {
    gootherpage(value) {
      this.$emit("showotherpage", value);
    },
    async gootherwx() {
      // let href = `${window.location.protocol}//test.mcnki.net/index?provider=wechat`;
       let href = `${window.location.protocol}//siem-icmlab.ac.cn/index?provider=wechat`;
      let uri = encodeURIComponent(href);
      getwxcode(uri).then((res) => {
        console.log(res)
        // window.open(res);
        this.userinfo();
        // debugger
      });
    },
    Codeget() {
      getVerificationCode()
        .then((res) => {
          // console.log(res);
          this.codeimg = res.data.data;
          this.form.codeKey = res.data.key;
        })
        .catch((err) => {
          console.log("验证码获取错误", err);
        });
    },
    loginin() {
      getloginin(this.form)
        .then((res) => {
          // console.log(res);
          localStorage.setItem("token", res.data.accessToken);
          
          this.userinfo();
          localStorage.setItem("userName",res.data.realName);
          this.$message.success("登录成功");
          this.$router.push('/userinfo')
        })
        .catch((err) => {
          console.log("登录失败问题", err);
          this.Codeget();
        });
    },
    userinfo() {
      getuserinfo()
        .then((res) => {
          console.log("用户信息", res);
          localStorage.setItem("userName", res.data.userName);
          this.$store.commit("setUserInfo", res);
        })
        .catch((err) => {
          localStorage.setItem("token", "");
          localStorage.setItem("userName", "");
        });
    },
  },
  created() {
    this.Codeget();
    // 声明浏览器缓存内数据
    let token = window.localStorage.getItem("token");
    let username = window.localStorage.getItem("userName");
    if (username) {
      console.log("缓存里有userName信息");
      this.form.username = username;
      // this.$router.push("/userinfo");
    }
  },
};
</script>

<style lang="less" scoped>
.SignIn {
  height: 100%;
  position: relative;
  .header {
    display: flex;
    justify-content: space-between;
    div {
      padding-top: 45px;
      padding-left: 47px;
    }
    img {
      cursor: pointer;
    }
  }
  .user-box {
    margin-top: 30px;
    margin-bottom: 24px;
    margin-right: 38px;
    margin: 30px 38px 24px 47px;
    input {
      outline: none;
      text-indent: 14px;
      border: 1px solid #dddddd;
      border-radius: 7px;
      &:focus {
        background: white;
        color: black;
      }
    }
    .name {
      width: 329px;
      height: 46px;
      background: #f4f4f4;
      &:focus {
        background: white;
        color: black;
      }
    }
    .password {
      width: 329px;
      height: 46px;
      margin-top: 18px;
      background: #f4f4f4;
    }
    .Tips {
      margin-top: 9px;
      .iconfont {
        color: #df534d;
        span {
          margin-left: 10px;
        }
      }
      color: #df534d;
    }
    .VerificationCode {
      display: flex;
      input {
        margin-top: 15px;
        width: 180px;
        height: 46px;
        background: #f4f4f4;
        &:focus {
          background: white;
          color: black;
        }
      }
      img {
        cursor: pointer;
        width: 117px;
        margin-left: 21px;
        margin-top: 15px;
      }
    }
    button {
      margin-top: 18px;
      width: 329px;
      height: 46px;
      border-radius: 7px 7px 7px 7px;
      background: #3468ad;
      border: 1px solid #dddddd;
      color: white;
      cursor: pointer;
    }
    .operation {
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;
      width: 97.5%;
      font-size: 14px;
      color: #9fa19f;
      div {
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .wximg {
      width: 32px;
      height: 32px;
      margin-left: 150px;
      margin-top: 24px;
      cursor: pointer;
      .icon {
        width: 100%;
        height: 100%;
      }
    }
  }
  .careful {
    font-size: 10px;
    position: absolute;
    bottom: 16px;
    color: #c8c8ca;
    width: 100%;
    text-align: center;
    text-overflow: hidden;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>