<template>
  <div>
    <div class="login">
      <div class="backIndex" @click="goIndex">
        <i class="el-icon-back"></i>返回首页
      </div>
      <div class="label"  @click="goIndex">集成电路圈</div>
      <div class="main-box">
        <SignIn v-if="typeo == 'signin'" @showotherpage="change"></SignIn>
        <QRCode v-else-if="typeo == 'qrcode'" @showotherpage="change"></QRCode>
        <ForgotPassword
          v-else-if="typeo == 'forgotpassword'"
          @showotherpage="change"
        ></ForgotPassword>
        <Register v-else @showotherpage="change"></Register>
      </div>
    </div>
  </div>
</template>

<script>
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import QRCode from "./QRCode";
import Register from "./Register";
export default {
  name: "login",
  components: {
    SignIn,
    ForgotPassword,
    QRCode,
    Register,
  },
  data() {
    return {
      typeo: "signin",
    };
  },
  methods: {
    change(data) {
      this.typeo = data;
    },
    goIndex() {
      this.$router.push("/index");
    },
  },
  mounted() {},
  activated() {
    if (this.$route.query.type) {
      this.typeo = "register";
    } else {
      this.typeo = "signin";
    }
  },
};
</script>

<style lang="less" scoped>
.login {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 658px;
  height: 520px;
  background-image: url("../../assets/images/loginbg.png");
  background-size: 100% 100%;
  cursor: pointer;
  .backIndex {
    margin-top: -20px;
    font-size: 16px;

    color: #9fa19f;
  }
  .backIndex:hover {
    cursor: pointer;
    color: #091d46;
  }
  .label {
    color: white;
    font-size: 18px;
    margin-top: 24px;
    margin-left: 26px;
    width: 100px;
    float: left;
  }
  .main-box {
    width: 422px;
    height: 100%;
    float: right;
  }
}
</style>