<template>
  <div class="QRCode">
    <div class="header">
      <div></div>
      <img
        @click="gootherpage('signin')"
        src="@/assets/images/diannao.png"
        alt=""
      />
    </div>
      <div class="erweima">
      <iframe id="wxcodepage" :src="codeUrl.data" frameborder="0" scrolling="no" height="330px"
      sandbox="allow-scripts allow-top-navigation allow-same-origin">
      </iframe>
        <p>使用微信扫码登录集成电路圈</p>
      <!-- <canvas id="codeUrl"></canvas> -->
      <!-- <p>使用微信扫码登录{{ title }}</p> -->
    </div>
    <!-- <div class="erweima">
      <canvas id="codeUrl"></canvas>
      <p>使用微信扫码登录碳材料圈</p>
    </div> -->
    <!-- <div class="operation">
      <div @click="gootherpage('signin')">账号登录</div>
      <div>|</div>
      <div @click="gootherpage('register')">快速注册</div>
    </div> -->
    <div class="careful">注册登录即代表同意 《服务协议》 和《隐私政策》</div>
  </div>
</template>

<script>
import { getuserinfo,getwxcode } from "@/api/index";
import QRCode from "qrcode"; //引入生成二维码插件
export default {
  name: "QRCode",
  components: {},
  data() {
    return {
      codeUrl: "",
    };
  },
  mounted() {
    this.start();
    // var rqcode = document.getElementById("codeUrl");
    // QRCode.toCanvas(rqcode, this.codeUrl, { width: 203 }, function (error) {
    //   if (error) console.error(error);
    // });
  },
  methods: {
    gootherpage(value) {
      this.$emit("showotherpage", value);
    },
        // 一些数据初始化
    async start() {
     
      // let href = `${window.location.protocol}//carbonlinks.cn/index?provider=wechat`;
      let href = `${window.location.protocol}//siem-icmlab.ac.cn/userinfo/basics?provider=wechat`;
      let uri = encodeURIComponent(href);
      this.codeUrl = await getwxcode(uri).then((res) => {
          console.log(res)
        
        return res;
        
      
      });

      // 生成二维码
      // var rqcode = document.getElementById("codeUrl");
      // QRCode.toCanvas(rqcode, res, { width: 203 }, function (error) {
      //   if (error) console.error(error);
      // });
    },
     userinfo() {
      getuserinfo()
        .then((res) => {
          console.log("用户信息", res);
          localStorage.setItem("userName", res.data.userName);
          this.$store.commit("setUserInfo", res);
        })
        .catch((err) => {
          localStorage.setItem("token", "");
          localStorage.setItem("userName", "");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.QRCode {
  position: relative;
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    div {
      padding-top: 45px;
      padding-left: 47px;
    }
    img {
      cursor: pointer;
    }
  }
  .erweima {
    width: 203px;
    height: 203px;
    margin: 0 auto;
    margin-top: 39px;
    #wxcodepage{
      margin-top: -20px;
      margin-left: -50px;
    }
    p {
      font-size: 12px;
      color: #9fa19f;
      text-align: center;
    }
    #codeUrl {
      width: 100% !important;
      height: 100% !important;
    }
  }
  // .erweima {
  //   width: 203px;
  //   height: 203px;
  //   margin: 0 auto;
  //   margin-top: 39px;
  //   p {
  //     font-size: 12px;
  //     color: #9fa19f;
  //     text-align: center;
  //   }
  //   #codeUrl{
  //     width: 100% !important;
  //     height: 100% !important;
  //   }
  // }
  .operation {
    display: flex;
    justify-content: center;
    margin-top: 81px;
    width: 97.5%;
    font-size: 14px;
    color: #9fa19f;
    div {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .careful {
    position: absolute;
    font-size: 10px;
    text-align: center;
    bottom: 16px;
    color: #c8c8ca;
    width: 80%;
    left: 10%;
    text-overflow: hidden;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>